
/* eslint-disable camelcase */
import { Component, Vue, Watch } from 'vue-property-decorator';
import Axios from 'axios';
import { TabIds } from '@/views/Home.vue';
import { useSysInfoStore } from '@/storeV2/sysInfo';
import { ProjectKey } from '@/types/projectKey';

@Component
export default class DamTable extends Vue {
  footerProps = { 'items-per-page-text': '行/1ページ:', 'items-per-page-options': [20, 50, 100, -1] };

  itemPerPage = -1;

  get isMobile() {
    return this['$store'].getters.isMobile;
  }

  get result() {
    return this['$store'].getters.result;
  }

  get basinId() {
    return this['$store'].getters.basinId;
  }

  get stationId() {
    return this['$store'].getters.stationId;
  }

  get targetTime() {
    return this['$store'].getters.formatTargetTime;
  }

  get latestTargetTime() {
    return this['$store'].getters.latestTargetTime;
  }

  get tabId(): TabIds {
    return this['$store'].getters.tabId;
  }

  get rerender() {
    return this['$store'].getters.rerender;
  }

  get displayScales() {
    return [
      { value: 'tenMinutes', label: '10分' },
      { value: 'oneHour', label: '1時間' },
      { value: 'everyHourOnTheHour', label: '毎正時' },
    ];
  }

  get displayItems() {
    if (this.displayScale === 'tenMinutes') {
      return JSON.parse(JSON.stringify(this.items)).map(((item) => {
        Object.keys(item).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
        });
        return item;
      }));
    }
    let count = 0;
    if (this.isDam) {
      const items: {
        datetime: string,
        data_datetime: string,
        R: any,
        Ruika: any,
        wlevel: any,
        Qin_all: any,
        Qout_all: any,
        Qout_all2: any,
      }[] = [];
      let RList: any[] = [];
      let QinAllList: any[] = [];
      let QoutAllList: any[] = [];
      let QoutAll2List: any[] = [];
      /**
       * 1時間平均を表示
       * 累加雨量と貯水位は平均をしない。当該時刻の10分値を表示する。
       */
      for (let i = 0; i < this.items.length; i += 1) {
        count += 1;
        RList.push(this.items[i].R);
        QinAllList.push(this.items[i].Qin_all);
        QoutAllList.push(this.items[i].Qout_all);
        QoutAll2List.push(this.items[i].Qout_all2);
        if (this.displayScale === 'oneHour') {
          if (this.items[i].datetime.slice(9, 11) === this.targetTime.slice(10, 12)) {
            items.push({
              datetime: this.items[i].datetime,
              data_datetime: this.items[i].data_datetime,
              R: this.getAverage(RList),
              Ruika: this.items[i].Ruika,
              wlevel: this.items[i].wlevel,
              Qin_all: this.getAverage(QinAllList),
              Qout_all: this.getAverage(QoutAllList),
              Qout_all2: this.getAverage(QoutAll2List),
            });
            count = 0;
            RList = [];
            QinAllList = [];
            QoutAllList = [];
            QoutAll2List = [];
          }
        } else if (this.displayScale === 'everyHourOnTheHour') {
          if (this.items[i].datetime.slice(9, 11) === '00') {
            if (count === 6) {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.getAverage(RList),
                Ruika: this.items[i].Ruika,
                wlevel: this.items[i].wlevel,
                Qin_all: this.getAverage(QinAllList),
                Qout_all: this.getAverage(QoutAllList),
                Qout_all2: this.getAverage(QoutAll2List),
              });
            } else {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.items[i].R,
                Ruika: this.items[i].Ruika,
                wlevel: this.items[i].wlevel,
                Qin_all: this.items[i].Qin_all,
                Qout_all: this.items[i].Qout_all,
                Qout_all2: this.items[i].Qout_all2,
              });
            }
            count = 0;
            RList = [];
            QinAllList = [];
            QoutAllList = [];
            QoutAll2List = [];
          }
        }
      }
      return items.map(((item) => {
        Object.keys(item).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
        });
        return item;
      }));
    } if (this.isWaterLevel) {
      const items: {
        datetime: string,
        data_datetime: string,
        R: any,
        Ruika: any,
        wlevel: any,
        flow: any,
        flow2: any,
      }[] = [];
      let RList: any[] = [];
      let wlevelList: any[] = [];
      let flowList: any[] = [];
      let flow2List: any[] = [];
      /**
       * 1時間平均を表示
       * 累加雨量は平均をしない。当該時刻の10分値を表示する。
       */
      for (let i = 0; i < this.items.length; i += 1) {
        count += 1;
        RList.push(this.items[i].R);
        wlevelList.push(this.items[i].wlevel);
        flowList.push(this.items[i].flow);
        flow2List.push(this.items[i].flow2);
        if (this.displayScale === 'oneHour') {
          if (this.items[i].datetime.slice(9, 11) === this.targetTime.slice(10, 12)) {
            items.push({
              datetime: this.items[i].datetime,
              data_datetime: this.items[i].data_datetime,
              R: this.getAverage(RList),
              Ruika: this.items[i].Ruika,
              wlevel: this.getAverage(wlevelList),
              flow: this.getAverage(flowList),
              flow2: this.getAverage(flow2List),
            });
            count = 0;
            RList = [];
            wlevelList = [];
            flowList = [];
            flow2List = [];
          }
        } else if (this.displayScale === 'everyHourOnTheHour') {
          if (this.items[i].datetime.slice(9, 11) === '00') {
            if (count === 6) {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.getAverage(RList),
                Ruika: this.items[i].Ruika,
                wlevel: this.getAverage(wlevelList),
                flow: this.getAverage(flowList),
                flow2: this.getAverage(flow2List),
              });
            } else {
              items.push({
                datetime: this.items[i].datetime,
                data_datetime: this.items[i].data_datetime,
                R: this.items[i].R,
                Ruika: this.items[i].Ruika,
                wlevel: this.items[i].wlevel,
                flow: this.items[i].flow,
                flow2: this.items[i].flow2,
              });
            }
            count = 0;
            RList = [];
            wlevelList = [];
            flowList = [];
            flow2List = [];
          }
        }
      }
      return items.map(((item) => {
        Object.keys(item).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item[key] = Math.floor(item[key]) <= -999 ? '-' : item[key];
        });
        return item;
      }));
    }
    return [];
  }

  get type() {
    return Number(this['$store'].getters.type);
  }

  get isWaterLevel() {
    return this.type === 4;
  }

  get isDam() {
    return this.type === 7;
  }

  /**
   * sim_resultの情報が更新された
   * (1)ダム地点
   * preStage廃止
   * Qout_all2の追加
   * (2)水位観測書地点
   * flow2の追加
   */
  get isResultV2() {
    if (!this.items.length) return false;
    const hasFlow2 = 'flow2' in this.items[this.items.length - 1];
    const hasQoutAll2 = 'Qout_all2' in this.items[this.items.length - 1];
    if (hasFlow2 || hasQoutAll2) {
      return true;
    }
    return false;
  }

  getDisplayData({ value, decimalPlaces, precision }: {
    value: string | number,
    decimalPlaces: number,
    precision?: number,
  }) {
    const parsedNum = Number(value);
    if (Number.isNaN(parsedNum)) {
      return '-';
    }
    if (!precision) {
      return parsedNum.toFixed(decimalPlaces);
    }
    const multiplier = 10 ** precision;
    const roundedNum = Math.round(parsedNum * multiplier) / multiplier;
    return roundedNum.toFixed(decimalPlaces);
  }

  getAverage(list) {
    const count = list.reduce((a, b) => a + (b <= -999 ? 0 : 1), 0);
    return count > 0 ? list.reduce((a, b) => a + (b <= -999 ? 0 : b), 0) / count : -999;
  }

  getHeader(className: 'dam' | 'waterLevel') {
    if (className === 'waterLevel') {
      const headerBase = [
        {
          text: '時刻', value: 'datetime', class: 'px-1', align: 'center', width: '80',
        },
        {
          text: '降雨強度(mm/h)', value: 'R', class: 'px-1', sortable: false, align: 'center', width: '70',
        },
        {
          text: '累加雨量(mm)', value: 'Ruika', class: 'px-1', sortable: false, align: 'center', width: '70',
        },
        {
          text: '河川水位(m)', value: 'wlevel', class: 'px-1', sortable: false, align: 'center', width: '70',
        },
        {
          text: '河川流量(㎥/s)', value: 'flow', class: 'px-1', sortable: false, align: 'center', width: '80',
        },
      ];
      if (this.isResultV2) {
        headerBase.pop();
        return [...headerBase, {
          text: '河川流量①*1\n(㎥/s)', value: 'flow', class: 'px-1', sortable: false, align: 'center', width: '81',
        },
        {
          text: '河川流量②*2\n(㎥/s)', value: 'flow', class: 'px-1', sortable: false, align: 'center', width: '81',
        },
        ];
      }
      return headerBase;
    }
    const headerBase = [
      {
        text: '時刻', value: 'datetime', class: 'px-1', align: 'center', width: '80',
      },
      {
        text: '降雨強度(mm/h)', value: 'R', class: 'px-1', sortable: false, align: 'right', width: '70',
      },
      {
        text: '累加雨量(mm)', value: 'Ruika', class: 'px-1', sortable: false, align: 'right', width: '70',
      },
      {
        text: '貯水位(EL.m)', value: 'wlevel', class: 'px-1', sortable: false, align: 'right', width: '70',
      },
      {
        text: '流入量(㎥/s)', value: 'Qin_all', class: 'px-1', sortable: false, align: 'right', width: '70',
      },
      {
        text: '放流量(㎥/s)', value: 'Qout_all', class: 'px-1', sortable: false, align: 'right', width: '70',
      },
    ];
    if (this.isResultV2) {
      headerBase.pop();
      return [...headerBase, {
        text: '放流量①*1\n(㎥/s)', value: 'Qout_all', class: 'px-1', sortable: false, align: 'center', width: '75',
      },
      {
        text: '放流量②*2\n(㎥/s)', value: 'Qout_all2', class: 'px-1', sortable: false, align: 'center', width: '75',
      }];
    }
    return headerBase;
  }

  displayScale: 'oneHour' | 'tenMinutes' | 'everyHourOnTheHour' = 'oneHour';

  private items: any[] = [];

  downloadResultWaitingForResponse = false;

  async downloadResult() {
    this.downloadResultWaitingForResponse = true;
    const result = await Axios.get(`/api/excel/${this.basinId}/${this.stationId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`);
    if (result.status === 200) {
      window.open(result.data.download_url);
    }
    this.downloadResultWaitingForResponse = false;
  }

  downloadAllPointWaitingForResponse = false;

  async downloadAllPoint() {
    const filteredStationsJsonStr = localStorage.getItem('filteredStations');
    const filteredStations = filteredStationsJsonStr ? JSON.parse(filteredStationsJsonStr) : [];
    const chunkedArray: any = [];
    for (let i = 0; i < filteredStations.length; i += 15) {
      const chunk = filteredStations.slice(i, i + 15);
      chunkedArray.push(chunk);
    }
    // サーバーレス対応している場合
    this.downloadAllPointWaitingForResponse = true;
    if (chunkedArray.length > 1) {
      alert(`ダウンロードする地点の数が多い為、${chunkedArray.length}分割でダウンロードされます。ご了承ください。`);
    }
    const result = await Axios.post(`/api/allExcel/${this.basinId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`, {
      stations: chunkedArray[0],
      part: chunkedArray.length > 1 ? 1 : null,
    });
    if (result.status === 200) {
      window.open(result.data.download_url);
    }

    if (chunkedArray.length > 1) {
      const result2 = await Axios.post(`/api/allExcel/${this.basinId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`, {
        stations: chunkedArray[1],
        part: 2,
      });
      if (result2.status === 200) {
        window.open(result2.data.download_url);
      }
    }

    if (chunkedArray.length > 2) {
      const result3 = await Axios.post(`/api/allExcel/${this.basinId}?tgttime=${this.targetTime}&isEn=${this.tabId === 'ensembleCalculation'}`, {
        stations: chunkedArray[2],
        part: 3,
      });
      if (result3.status === 200) {
        window.open(result3.data.download_url);
      }
    }

    // これ以上はダウンロードできない

    this.downloadAllPointWaitingForResponse = false;
  }

  show10MinCopyButton() {
    const sysInfoStore = useSysInfoStore();
    if (sysInfoStore.projectKey === ProjectKey.Fukuoka) {
      return true;
    }

    return false;
  }

  copyResultData() {
    let copyString = 'datetime\twlevel\tpreStage\tQin_all\tQout_all\tR\tRuika\n';
    this.result.sim_result.forEach((result) => {
      copyString += result.datetime;
      copyString += `\t${result.wlevel}`;
      copyString += `\t${result.preStage}`;
      copyString += `\t${result.Qin_all}`;
      copyString += `\t${result.Qout_all}`;
      copyString += `\t${result.R}`;
      copyString += `\t${result.Ruika}\n`;
    });

    // eslint-disable-next-line no-tabs
    navigator.clipboard.writeText(copyString)
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('10分間隔の予測結果をコピーしました。');
      });
  }

  getBackgroundColor(datetime) {
    const targetData = new Date(datetime.replace(/-/g, '/'));
    const year = this.targetTime.slice(0, 4);
    const month = this.targetTime.slice(4, 6);
    const day = this.targetTime.slice(6, 8);
    const timeH = this.targetTime.slice(8, 10);
    const timeM = this.targetTime.slice(10, 12);
    const convertedTargetDate = new Date(`${year}/${month}/${day} ${timeH}:${timeM}`);
    if (targetData.getTime() === convertedTargetDate.getTime()) {
      return '#f7ef79';
    }
    if (targetData.getTime() <= convertedTargetDate.getTime()) {
      return '#e2f4e2';
    }
    return '#c9e4ec';
  }

  @Watch('result')
  @Watch('rerender')
  async updateResult() {
    this.items = this.result.sim_result.map((result) => {
      const month = result.datetime.slice(5, 7);
      const [day, time] = result.datetime.slice(8, 16).split(' ');
      return {
        ...result,
        data_datetime: result.datetime,
        datetime: `${month}/${day} ${time}`,
      };
    });
  }
}
