import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    kurihashiKakuhoMap: {},
    loading: true,
    tableInfo: null,
    header: {},

    helpTeam: '',
    contents: [],
    supportInfoList: [],
    printMode: false,
    isMobile: false,
    rerender: false,
    showDrawer: false,
    basinId: null,
    stationId: null,
    // ダムか観測所か7 or 4
    type: null,
    stationsMap: {},
    // 対象時間
    targetTime: null as any,
    enTargetTime: null as any,
    // 最新の対象可能時間
    latestTargetTime: null,
    enLatestTargetTime: null,
    // 予測表示期間
    yskSpan: 24 * 4,
    enYskSpan: 24 * 7,
    // 観測所の予測処理結果
    result: {
      sim_result: [],
    },
    enResult: null,

    dischargeInfo: null,

    // 降雨スケール
    kouuScales: [10, 25, 50, 100, 200],
    kouuScale: 0,
    kouuAutoScale: 0,
    // 累加スケール
    ruikaScales: [250, 500, 1000, 2000],
    ruikaScale: 0,
    ruikaAutoScale: 0,
    // 最小水位
    minHScales: [],
    minHAutoScale: 0,
    minHScale: 0,
    // 最大水位
    maxHScales: [],
    maxHAutoScale: 0,
    maxHScale: 0,
    // 最大流量
    maxQScales: [],
    maxQScale: 0,
    maxQAutoScale: 0,

    // アンサンブル表示メンバー
    ensampleDisplayMember: 'topMidBot',

    tabId: 'autoCalculation',

    autoLoad: false,
    intervalId: null,

    // 上位
    top: null,
    // 中位
    middle: null,
    // 下位
    bottom: null,

    // (loading: 読み込み中) (hide: 非表示)  (show: 表示)
    ensampleTabStatusStatus: 'hide',

    isJizenHouryu: true,

    // 3水位比較
    suiiResultList: [],

    selectedClass: '全部',
  },
  getters: {
    /**
     * sim_resultの情報が更新された
     * (1)ダム地点
     * preStage廃止
     * Qout_all2の追加
     * (2)水位観測書地点
     * flow2の追加
     */
    isResultV2(state) {
      if (state.result?.sim_result.length) {
        const hasFlow2 = 'flow2' in state.result.sim_result[state.result.sim_result.length - 1];
        const hasQoutAll2 = 'Qout_all2' in state.result.sim_result[state.result.sim_result.length - 1];
        if (hasFlow2 || hasQoutAll2) {
          return true;
        }
      }
      return false;
    },
    kurihashiKakuhoMap(state) { return state.kurihashiKakuhoMap; },
    tableInfo(state) { return state.tableInfo; },
    loading(state) { return state.loading; },
    header(state) { return state.header; },
    helpTeam(state) { return state.helpTeam; },
    contents(state) { return state.contents; },
    supportInfoList(state) { return state.supportInfoList; },
    currentContent(state) {
      const content: any = state.contents.find((item: any) => item.id === state.tabId);
      if (content) {
        return content;
      }
      return {};
    },
    currentStationIds(state, getters) {
      return getters.currentContent?.stations ?? [];
    },
    // https://matplotlib.org/stable/tutorials/colors/colormaps.html -Qualitative Set1-
    colorMapArray() { return ['#C62B01', '#1D609A', '#309126', '#7A2F84', '#D96100', '#DEDF00', '#883802', '#D863A0', '#7A7A7A']; },
    printMode(state) { return state.printMode; },
    isMobile(state) { return state.isMobile; },
    rerender(state) { return state.rerender; },
    showDrawer(state) { return state.showDrawer; },
    basinId(state) { return state.basinId; },
    stationId(state) { return state.stationId; },
    type(state) { return state.type; },
    stationsMap(state) { return state.stationsMap; },
    result(state) { return state.result; },
    enResult(state) { return state.enResult; },

    dischargeInfo(state) { return state.dischargeInfo; },

    targetTime(state) { return state.targetTime; },
    enTargetTime(state) { return state.enTargetTime; },

    formatTargetTime(state) {
      const tgt = state.targetTime;
      if (tgt) {
        return tgt.split('-').join('').split(' ').join('')
          .split(':')
          .join('');
      }
      return null;
    },

    enFormatTargetTime(state) {
      const tgt = state.enTargetTime;
      if (tgt) {
        return tgt.split('-').join('').split(' ').join('')
          .split(':')
          .join('');
      }
      return null;
    },

    latestTargetTime(state) { return state.latestTargetTime; },
    enLatestTargetTime(state) { return state.enLatestTargetTime; },
    yskSpan(state) { return state.yskSpan; },
    enYskSpan(state) { return state.enYskSpan; },
    gridPitch(state) { return Math.max(1, state.yskSpan / 12); },
    enGridPitch(state) { return Math.max(1, state.enYskSpan / 12); },
    kouuScale(state) { return state.kouuScale; },
    kouuAutoScale(state) { return state.kouuAutoScale; },
    kouuScales(state) { return state.kouuScales; },
    ruikaScale(state) { return state.ruikaScale; },
    ruikaAutoScale(state) { return state.ruikaAutoScale; },
    ruikaScales(state) { return state.ruikaScales; },

    minHScale(state) { return state.minHScale; },
    minHScales(state) { return state.minHScales; },
    minHAutoScale(state) { return state.minHAutoScale; },

    maxHScale(state) { return state.maxHScale; },
    maxHScales(state) { return state.maxHScales; },
    maxHAutoScale(state) { return state.maxHAutoScale; },

    maxQScale(state) { return state.maxQScale; },
    maxQAutoScale(state) { return state.maxQAutoScale; },
    maxQScales(state) { return state.maxQScales; },

    ensampleDisplayMember(state) { return state.ensampleDisplayMember; },

    autoLoad(state) { return state.autoLoad; },
    intervalId(state) { return state.intervalId; },

    top(state) { return state.top; },
    middle(state) { return state.middle; },
    bottom(state) { return state.bottom; },

    ensampleTabStatusStatus(state) { return state.ensampleTabStatusStatus; },

    tabId(state) { return state.tabId; },

    isJizenHouryu(state) { return state.isJizenHouryu; },

    suiiResultList(state) { return state.suiiResultList; },

    selectedClass(state) { return state.selectedClass; },
  },
  mutations: {
    setKurihashiKakuhoMap(state, payload) {
      state.kurihashiKakuhoMap = payload;
    },
    setTableInfo(state, payload) {
      state.tableInfo = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setHelpTeam(state, payload) {
      state.helpTeam = payload;
    },
    setHeader(state, payload) {
      state.header = payload;
    },
    setContents(state, payload) {
      state.contents = payload;
    },
    setSupportInfoList(state, payload) {
      state.supportInfoList = payload;
    },
    setPrintMode(state, payload) {
      state.printMode = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setStationsMap(state, payload) {
      state.stationsMap = payload;
    },
    setBasinId(state, payload) {
      state.basinId = payload;
    },
    setRerender(state, payload) {
      state.rerender = payload;
    },
    setShowDrawer(state, payload) {
      state.showDrawer = payload;
    },
    setStationId(state, payload) {
      state.stationId = payload;
    },
    setType(state, payload) {
      state.type = payload;
    },
    setResult(state, payload) {
      state.result = payload;
    },
    setEnResult(state, payload) {
      state.enResult = payload;
    },
    setDischargeInfo(state, payload) {
      state.dischargeInfo = payload;
    },
    setYskSpan(state, payload) {
      state.yskSpan = payload;
    },
    setEnYskSpan(state, payload) {
      state.enYskSpan = payload;
    },
    setKouuScale(state, payload) {
      state.kouuScale = payload;
    },
    setKouuAutoScale(state, payload) {
      state.kouuAutoScale = payload;
    },
    setRuikaScale(state, payload) {
      state.ruikaScale = payload;
    },
    setRuikaAutoScale(state, payload) {
      state.ruikaAutoScale = payload;
    },
    setMinHScale(state, payload) {
      state.minHScale = payload;
    },
    setMinHScales(state, payload) {
      state.minHScales = payload;
    },
    setMinHAutoScale(state, payload) {
      state.minHAutoScale = payload;
    },
    setMaxHScale(state, payload) {
      state.maxHScale = payload;
    },
    setMaxHScales(state, payload) {
      state.maxHScales = payload;
    },
    setMaxHAutoScale(state, payload) {
      state.maxHAutoScale = payload;
    },
    setMaxQScale(state, payload) {
      state.maxQScale = payload;
    },
    setMaxQAutoScale(state, payload) {
      state.maxQAutoScale = payload;
    },

    setMaxQScales(state, payload) {
      state.maxQScales = payload;
    },
    setEnsampleDisplayMember(state, payload) {
      state.ensampleDisplayMember = payload;
    },
    setTargetTime(state, payload) {
      state.targetTime = payload;
    },
    setEnTargetTime(state, payload) {
      state.enTargetTime = payload;
    },
    setLatestTargetTime(state, payload) {
      state.latestTargetTime = payload;
    },
    setEnLatestTargetTime(state, payload) {
      state.enLatestTargetTime = payload;
    },
    setTabId(state, payload) {
      state.tabId = payload;
    },
    setIsJizenHouryu(state, payload) {
      state.isJizenHouryu = payload;
    },
    setSuiiResultList(state, payload) {
      state.suiiResultList = payload;
    },
    setSelectedClass(state, payload) {
      state.selectedClass = payload;
    },
    setAutoLoad(state, payload) {
      state.autoLoad = payload;
    },
    setIntervalId(state, payload) {
      state.intervalId = payload;
    },
    setTop(state, payload) {
      state.top = payload;
    },
    setMiddle(state, payload) {
      state.middle = payload;
    },
    setBottom(state, payload) {
      state.bottom = payload;
    },
    setEnsampleTabStatus(state, payload) {
      state.ensampleTabStatusStatus = payload;
    },
  },
});
