
import { Component, Mixins, Watch } from 'vue-property-decorator';
import LineChart from './LineChart';
import ChartMixin from './ChartMixin.vue';

@Component({
  components: {
    LineChart,
  },
})
export default class EnChosuiChart extends Mixins(ChartMixin) {
  private chartOptions = null;

  get rerender() {
    return this['$store'].getters.rerender;
  }

  set rerender(value) {
    this['$store'].commit('setRerender', value);
  }

  get gridPitch() {
    return this['$store'].getters.enGridPitch;
  }

  get minHScales() {
    return this['$store'].getters.minHScales;
  }

  get minHScale() {
    return this['$store'].getters.minHScale === '自動' ? this['$store'].getters.minHAutoScale : this['$store'].getters.minHScale;
  }

  get maxHScales() {
    return this['$store'].getters.maxHScales;
  }

  get maxHScale() {
    return this['$store'].getters.maxHScale === '自動' ? this['$store'].getters.maxHAutoScale : this['$store'].getters.maxHScale;
  }

  get ensampleDisplayMember() {
    return this['$store'].getters.ensampleDisplayMember;
  }

  get targetTime() {
    return this['$store'].getters.enTargetTime;
  }

  get yskSpan() {
    return this['$store'].getters.enYskSpan;
  }

  mounted() {
    this.updateResult();
  }

  getOneFromResult(result, key) {
    return result.map((obj) => (Math.floor(obj[key]) <= -999 ? null : obj[key]));
  }

  get autoHStep() {
    const { stationsMap } = this['$store'].getters;
    return stationsMap[this.stationId].scales.autoHStep;
  }

  @Watch('tab')
  @Watch('yskSpan')
  @Watch('enResult')
  @Watch('rerender')
  @Watch('ensampleDisplayMember')
  public async updateResult() {
    const { type } = this['$store'].getters;

    // const topPreStageArray = this.getOneFromResult(this.enResult[0], 'preStage');
    const topWlevelArray = this.getOneFromResult(this.enResult[0], 'wlevel');

    // const midPreStageArray
    // = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'preStage');
    const midWlevelArray = this.getOneFromResult(this.enResult[Math.floor(this.enResult.length / 2)], 'wlevel');

    // const botPreStageArray
    // = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'preStage');
    const botWlevelArray = this.getOneFromResult(this.enResult[this.enResult.length - 1], 'wlevel');

    const dataTimeArray = this.enResult[0].map((obj) => obj.datetime);
    const { stationsMap } = this['$store'].getters;

    const datasets = stationsMap[this.stationId].limitLines.map((line) => {
      const data: any = [];
      line.details.forEach((detail) => {
        const startDate = new Date(this.targetTime);
        const endDate = new Date(this.targetTime);
        startDate.setDate(detail.start.split('/')[1]);
        startDate.setMonth(detail.start.split('/')[0] - 1);
        endDate.setDate(detail.end.split('/')[1]);
        endDate.setMonth(detail.end.split('/')[0] - 1);
        dataTimeArray.slice(0, 7 + this.yskSpan).forEach((dataTime) => {
          const targetData = new Date(dataTime);
          if (startDate < targetData && targetData < endDate) {
            data.push(detail.value);
          }
        });
      });
      let showUsageGuide = false;
      data.forEach((val) => {
        if (val && this.minHScale <= val && val <= this.maxHScale) {
          showUsageGuide = true;
        }
      });
      return {
        label: line.name,
        data,
        fill: false,
        pointRadius: 0,
        borderWidth: 2,
        borderColor: line.color,
        borderDash: [5, 3],
        showUsageGuide,
        style: `border-top-color: ${line.color};border-top-style: dotted;`,
      };
    });

    if (Number(type) === 7) {
      let chosuiiDatasets = this.enResult.map((result) => ({
        label: '貯水位',
        fill: false,
        data: result.map((obj) => obj.wlevel),
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#c0cfff',
        showUsageGuide: true,
        style: 'border-top-color: #c0cfff;',
        notShowLable: true,
        fixed: true,
      }));

      // let jizenDatasets = this.enResult.map((result) => ({
      //   label: '事前放流低下目標水位',
      //   fill: false,
      //   data: result.map((obj) => obj.preStage),
      //   pointRadius: 0,
      //   borderWidth: 2,
      //   borderColor: '#c0c08f',
      //   style: 'border-top-color: #c0c08f;',
      //   notShowLable: true,
      //   fixed: true,
      // }));

      if (this.ensampleDisplayMember === 'all') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1);
        // jizenDatasets = jizenDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1);
        // jizenDatasets[0]['notShowLable'] = false;
      } else if (this.ensampleDisplayMember === 'top') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && i < 5);
        // jizenDatasets = jizenDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1 && i < 5);
        // jizenDatasets[0]['notShowLable'] = false;
      } else if (this.ensampleDisplayMember === 'bot') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
        // jizenDatasets = jizenDatasets.filter((_result, i) => i !== 0
        //         && i !== Math.floor(this.enResult.length / 2)
        //         && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
        // jizenDatasets[0]['notShowLable'] = false;
      } else {
        chosuiiDatasets = [];
        // jizenDatasets = [];
      }

      if (chosuiiDatasets.length > 0) {
        chosuiiDatasets[0].notShowLable = false;
      }

      let maxNum = Math.max(...topWlevelArray, ...midWlevelArray, ...botWlevelArray);
      let minNum = Math.min(...topWlevelArray, ...midWlevelArray, ...botWlevelArray);
      chosuiiDatasets.forEach((dataset) => {
        maxNum = Math.max(maxNum, ...dataset.data);
        minNum = Math.min(minNum, ...dataset.data);
      });
      this['$store'].commit('setMaxHAutoScale', Math.ceil(maxNum / this.autoHStep) * this.autoHStep);
      this['$store'].commit('setMinHAutoScale', Math.floor(minNum / this.autoHStep) * this.autoHStep);
      const { maxHAutoScale, minHAutoScale } = this['$store'].getters;
      if (Math.abs(maxHAutoScale - minHAutoScale) <= this.autoHStep) {
        this['$store'].commit('setMaxHAutoScale', maxHAutoScale + this.autoHStep);
      }

      this.chartData = {
        labels: dataTimeArray.slice(0, 7 + this.yskSpan),
        datasets: [
          {
            label: '上位貯水位',
            fill: false,
            data: topWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#f00',
            style: 'border-top-color: #f00;',
            showUsageGuide: true,
            fixed: true,
          },
          // {
          //   label: '上位事前放流低下目標水位',
          //   fill: false,
          //   data: topPreStageArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#f88',
          //   style: 'border-top-color: #f88;',
          //   fixed: true,
          // },
          {
            label: '中位貯水位',
            fill: false,
            data: midWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#00f',
            style: 'border-top-color: #00f;',
            showUsageGuide: true,
            fixed: true,
          },
          // {
          //   label: '中位事前放流低下目標水位',
          //   fill: false,
          //   data: midPreStageArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#88f',
          //   style: 'border-top-color: #88f;',
          //   fixed: true,
          // },
          {
            label: '下位貯水位',
            fill: false,
            data: botWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#080',
            style: 'border-top-color: #080;',
            showUsageGuide: true,
            fixed: true,
          },
          // {
          //   label: '下位事前放流低下目標水位',
          //   fill: false,
          //   data: botPreStageArray,
          //   pointRadius: 0,
          //   borderWidth: 2,
          //   borderColor: '#0f0',
          //   style: 'border-top-color: #0f0;',
          //   fixed: true,
          // },
          ...chosuiiDatasets,
          // ...jizenDatasets,
          ...datasets,
        ],
      };
    } else {
      let chosuiiDatasets = this.enResult.map((result) => ({
        label: '水位',
        fill: false,
        data: result.map((obj) => obj.wlevel),
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#c0cfff',
        style: 'border-top-color: #c0cfff;',
        showUsageGuide: true,
        notShowLable: true,
        fixed: true,
      })).filter((_result, i) => i !== 0
              && i !== Math.floor(this.enResult.length / 2)
              && i !== this.enResult.length - 1);

      if (this.ensampleDisplayMember === 'all') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1);
      } else if (this.ensampleDisplayMember === 'top') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && i < 5);
      } else if (this.ensampleDisplayMember === 'bot') {
        chosuiiDatasets = chosuiiDatasets.filter((_result, i) => i !== 0
                && i !== Math.floor(this.enResult.length / 2)
                && i !== this.enResult.length - 1 && this.enResult.length - 1 - 10 < i);
      } else {
        chosuiiDatasets = [];
      }

      if (chosuiiDatasets.length > 0) {
        chosuiiDatasets[0].notShowLable = false;
      }

      this.chartData = {
        labels: dataTimeArray.slice(0, 7 + this.yskSpan),
        datasets: [
          {
            label: '上位水位',
            fill: false,
            data: topWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#f00',
            style: 'border-top-color: #f00;',
            fixed: true,
            showUsageGuide: true,
          },
          {
            label: '中位水位',
            fill: false,
            data: midWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#00f',
            style: 'border-top-color: #00f;',
            fixed: true,
            showUsageGuide: true,
          },
          {
            label: '下位水位',
            fill: false,
            data: botWlevelArray,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: '#0f0',
            style: 'border-top-color: #0f0;',
            fixed: true,
            showUsageGuide: true,
          },
          ...chosuiiDatasets,
          ...datasets,
        ],
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, obj) => `${obj.datasets[tooltipItem.datasetIndex].label}:${Number(tooltipItem.value).toFixed(2)}`,
        },
      },
      legend: {
        labels: {
          filter: () => false,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          scaleID: 'x-axis-0',
          value: this.targetTime,
          borderWidth: 2,
          borderColor: 'gray',
        }],
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              stepSize: this.gridPitch,
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
            gridLines: {
              color: '#999',
            },
          },
          {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD',
              },
            },
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              zeroLineColor: 'rgba(0, 0, 0, 0.1)',
              color: '#999',
            },
            ticks: {
              fontSize: 14,
              fontColor: 'black',
            },
          },
        ],
        yAxes: [{
          afterFit(scaleInstance) {
            // eslint-disable-next-line no-param-reassign
            scaleInstance.width = 40;
          },
          afterBuildTicks: (scale) => {
            let num = this.maxHScale;
            // eslint-disable-next-line no-param-reassign
            scale.ticks = [num];
            const step = (this.maxHScale - this.minHScale) / 10;
            while (scale.ticks.length < 11) {
              num -= step;
              // eslint-disable-next-line no-param-reassign
              scale.ticks.push(num);
            }
          },
          gridLines: {
            color: '#999',
          },
          ticks: {
            callback: (value: any) => value.toFixed(1),
            stepSize: 0.1,
            min: this.minHScale,
            max: this.maxHScale,
            // callback: (value: any) => {
            //   if (this.minHScale <= 10 && this.maxHScale <= 10) {
            //     return value;
            //   }
            //   return ((value % 10) === 0) ? value : '';
            // },
            // maxTicksLimit: 8,
            // min: this.minHScale,
            // max: this.maxHScale,
            // stepSize: 1,
            fontSize: 14,
            fontColor: 'black',
          },
        },
        {
          position: 'right',
          display: true,
          gridLines: {
            color: '#999',
          },
          ticks: {
            callback() { return ''; },
            fontSize: 14,
            fontColor: 'black',
          },
        },
        ],
      },
    };
  }
}
